import React from 'react';

interface NotificationCardProps {
  type: 'success' | 'info' | 'warning' | 'error';
  message: string;
  size?: 'small' | 'medium' | 'large';
}

const NotificationCard: React.FC<NotificationCardProps> = ({ type, message, size = 'medium' }) => {
  const styles = {
    success: {
      bg: 'bg-green-100 dark:bg-green-900',
      border: 'border-green-500 dark:border-green-700',
      text: 'text-green-900 dark:text-green-100',
      iconColor: 'text-green-600',
      shadowColor: 'bg-green-500',
      label: 'Success',
    },
    info: {
      bg: 'bg-blue-100 dark:bg-blue-900',
      border: 'border-blue-500 dark:border-blue-700',
      text: 'text-blue-900 dark:text-blue-100',
      iconColor: 'text-blue-600',
      shadowColor: 'bg-blue-500',
      label: 'Info',
    },
    warning: {
      bg: 'bg-yellow-100 dark:bg-yellow-900',
      border: 'border-yellow-500 dark:border-yellow-700',
      text: 'text-yellow-900 dark:text-yellow-100',
      iconColor: 'text-yellow-600',
      shadowColor: 'bg-yellow-500',
      label: 'Warning',
    },
    error: {
      bg: 'bg-red-100 dark:bg-red-900',
      border: 'border-red-500 dark:border-red-700',
      text: 'text-red-900 dark:text-red-100',
      iconColor: 'text-red-600',
      shadowColor: 'bg-red-500',
      label: 'Error',
    },
  };

  const { bg, border, text, iconColor, shadowColor, label } = styles[type];

  const sizeStyles: Record<'small' | 'medium' | 'large', React.CSSProperties> = {
    small: {
      width: '200px',
      padding: '8px',
    },
    medium: {
      width: '400px',
      padding: '12px',
    },
    large: {
      width: '800px',
      padding: '16px',
    },
  };

  const appliedSizeStyle = sizeStyles[size] || sizeStyles.medium;

  return (
    <div
      role="alert"
      className={`${bg} ${border} ${text} rounded-lg flex items-center relative transition duration-300 ease-in-out hover:bg-opacity-90 transform hover:scale-105 shadow-lg`}
      style={{
        ...appliedSizeStyle,
        position: 'relative',
      }}
    >
      {/* Pseudo-element for shadow effect on the left with dynamic color */}
      <span
        className={`absolute top-0 left-0 h-full w-2 ${shadowColor}`}
        style={{
          opacity: 1,
          transform: 'translateX(0px)', // Offset to the left
          borderTopRightRadius: '-5px', // Sharp corner on the right
          borderBottomRightRadius: '-5px', // Sharp corner on the right
          borderTopLeftRadius: 'inherit', // Rounded on the left
          borderBottomLeftRadius: 'inherit', // Rounded on the left
        }}
      />
      <svg
        stroke="currentColor"
        viewBox="0 0 24 24"
        fill="none"
        className={`h-5 w-5 flex-shrink-0 mr-2 ${iconColor}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 16h-1v-4h1m0-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          strokeWidth={2}
          strokeLinejoin="round"
          strokeLinecap="round"
        />
      </svg>
      <p className="text-xs font-semibold">{`${label} - ${message}`}</p>
    </div>
  );
};

export default NotificationCard;