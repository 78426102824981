import React from 'react';
import {USER_TYPES} from 'API';
import classNames from 'classnames';
import {IconLoader} from 'components/loader';
import ShouldRender from 'components/shouldRender';
import {Modal, OverlayTrigger, Spinner, Tooltip} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {usePhoneNumber} from '../../hooks/usePhoneNumber';
import PhoneInput from '../ui/PhoneInput';
import {AuthRoutes} from "../../utils";
import SocialInput from "../SocialInput/SocialInput";

const tooltip = ({text}) => (
    <Tooltip placement="bottom" className="in" id="tooltip-bottom">
        {text}
    </Tooltip>
);

export const UserProfileDetailsCard = ({
                                           avatar,
                                           profileState,
                                           updateState,
                                           onChooseAvatar,
                                           isSocialSignIn,
                                           onCopy,
                                           onSubmit,
                                           onCloseModal,
                                           isModalOpen,
                                           modalMessage,
                                           isLoading,
                                           formState,
                                           error,
                                           formError,
                                       }) => {
    const {countryCode} = usePhoneNumber(formState.phone_number || '');
    return (
        <div>
            <div className="border border-[#F5F1E8] bg-white rounded-[16px] mb-[30px]">
                <div className="brand-dashboard__profile-title mb-[22px] d-flex justify-between items-center">
                    <div
                        className="text-[#0E0D0D] uppercase mt-[31px] sm:ml-[28px] ml-[15px] head-text text-[14px] flex items-center font-[700]">
                        <h6>User Account</h6>
                    </div>
                </div>
                {avatar != null ? (
                    <>
                        <div className="sm:ml-[28px] ml-[15px] flex items-center">
                            <img
                                className="h-[120px] w-[120px] rounded-full object-cover"
                                src={avatar || '/images/default-image.png'}
                            />
                            <input
                                id="upload-avatar"
                                type="file"
                                className="hidden"
                                accept="image/*"
                                onChange={(e) => onChooseAvatar(e.target.files)}
                            />
                            <label className="flex justify-center ml-[25px]">
                                <label
                                    htmlFor="upload-avatar"
                                    className="cursor-pointer text-[16px]"
                                >
                                    <h6>Edit</h6>
                                    <h6>Replace</h6>
                                </label>
                            </label>
                        </div>
                    </>
                ) : (
                    <div className="h-[120px] w-[120px] flex justify-center items-center">
                        <Spinner
                            animation="border"
                            className="spinner-border"
                            variant="primary"
                        />
                    </div>
                )}
                <div className="mt-[17px]">
                    <div className="sm:mx-[28px] mx-[15px]">
                        {isSocialSignIn && (
                            <p className="mb-4 text-gray-400">
                                You can't change the profile information as you logged in
                                using an external provider.
                            </p>
                        )}
                        <div className="profile-inputs">
                            <div className="lg:grid grid-cols-12 gap-[40px]">
                                <div className="brand-dashboard__profile-group col-span-12 mb-[20px]">
                                    <input
                                        className="profile-input placeholder-gray-600"
                                        value={formState.name}
                                        placeholder="Full name"
                                        onChange={(e) => updateState('name', e.target.value)}
                                        disabled={isSocialSignIn}
                                    />
                                    <ShouldRender if={formError.name}>
                                        <p className="error-text">{formError.name}</p>
                                    </ShouldRender>
                                </div>
                            </div>

                            <div className="lg:grid grid-cols-12">
                                <div className="brand-dashboard__profile-group col-span-12">
                                    {profileState.data?.userType === USER_TYPES.CREATIVE_USER &&
                                        profileState.data?.uniqueId != null && (
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={tooltip({text: 'Creator Id'})}
                                            >
                                                <input
                                                    className="profile-input placeholder-gray-600 mb-[20px]"
                                                    value={profileState.data?.uniqueId || ''}
                                                    placeholder="Creator Id"
                                                    onMouseDown={onCopy}
                                                    readOnly={true}
                                                />
                                            </OverlayTrigger>
                                        )}

                                    <input
                                        className="profile-input placeholder-gray-600"
                                        value={formState.email}
                                        placeholder="Email address"
                                        onChange={(e) => updateState('email', e.target.value)}
                                        readOnly
                                    />
                                    <ShouldRender if={formError.email}>
                                        <p className="error-text">{formError.email}</p>
                                    </ShouldRender>
                                </div>

                                <div className="brand-dashboard__profile-group mb-[20px] col-span-12 gap-[40px] ">
                                    <PhoneInput
                                        value={formState.phone_number}
                                        defaultCountry={countryCode}
                                        placeholder="Phone Number"
                                        onChange={(_, __, ___, formattedValue) => {
                                            updateState('phone_number', formattedValue);
                                        }}
                                    />
                                </div>
                            </div>

                            {profileState.data?.userType === USER_TYPES.CREATIVE_USER && (
                                <>
                                    <SocialInput
                                        value={formState.tiktokHandler}
                                        onChange={(value) => updateState('tiktokHandler', value)}
                                        placeholder="Paste your TikTok profile handle here"
                                        icon="/images/tiktok-icon.svg"
                                    />
                                    <SocialInput
                                        value={formState.instagramHandler}
                                        onChange={(value) => updateState('instagramHandler', value)}
                                        placeholder="Paste your Instagram profile handle here"
                                        icon="/images/instagram-icon.png"
                                    />
                                    <SocialInput
                                        value={formState.youtubeHandler}
                                        onChange={(value) => updateState('youtubeHandler', value)}
                                        placeholder="Paste your YouTube channel handle here"
                                        icon="/images/youtube-linking-icon.svg"
                                    />
                                </>
                            )}
                        </div>
                        <div>
                            <h5 className="">
                                <Link to={AuthRoutes.ChangePass}>Change password</Link>
                            </h5>
                        </div>
                        <ShouldRender if={error}>
                            <p className="error-text">{error}</p>
                        </ShouldRender>
                        <div
                            className="save-button-container mt-[22px] mb-[24px] flex justify-end flex-row lg:gap-[80px] gap-[40px]">
                            <button className="creator-button bg-black" onClick={onSubmit}>
                <span className={classNames({loading: isLoading})}>
                  Update
                </span>
                                {isLoading && <IconLoader color="#005f73"/>}
                            </button>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={isModalOpen}
                    handleClose={onCloseModal}
                    type="brand"
                    content={modalMessage}
                />
            </div>
        </div>
    );
};