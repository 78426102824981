import { forwardRef, useCallback, useEffect, useState } from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';
import Switch from 'components/Switch/Switch';
import { StyledTextField } from 'components/TextField';
import { getlistAdGroups, UseGetTiktokCampaigns } from 'hooks/query/useBrandBrief';
import { Controller, useFormContext } from 'react-hook-form';
import NotificationCard from 'components/NotificationCard/NotificationCard';
import {
  CallToActionOptions,
  TiktokAdgroupOptions,
  TiktokCampaignOptions,
  TiktokIdentitiesOptions,
} from './components/TiktokOptions/TiktokOptions';

const TiktokTab = forwardRef(({ brandBrief }, ref) => {
  const { getCampaignList, data: campaignData, loading: campaignsLoading } = UseGetTiktokCampaigns();
  const { getAdGroupList, data: adGroupData, loading: adGroupsLoading } = getlistAdGroups();
  const { register, getValues, setValue, watch, control } = useFormContext();

  // State for error messages
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const getCampaignsAndIdentities = useCallback(async () => {
    const tiktokAccess = brandBrief.brandProfile?.userProfile?.tiktokAccountAccess;

    if (!tiktokAccess?.access_token || !tiktokAccess.advertiser_id) {
      if (!errorMessage) {
        setErrorMessage("TikTok access token or advertiser ID is missing. Please link your account.");
      }
      return;
    }

    await getCampaignList({
      variables: {
        advertiser_id: tiktokAccess.advertiser_id,
        token: tiktokAccess.access_token,
      },
    });
  }, [brandBrief, getCampaignList, errorMessage]);

  const getAdGroups = useCallback(async () => {
    const tiktokAccess = brandBrief.brandProfile?.userProfile?.tiktokAccountAccess;

    if (!tiktokAccess?.access_token || !tiktokAccess.advertiser_id) {
      if (!errorMessage) {
        setErrorMessage("TikTok access token or advertiser ID is missing. Please link your account.");
      }
      return;
    }

    const campaignId = getValues('tiktokData.campaignId');
    if (!campaignId) {
      if (!errorMessage) {
        setErrorMessage("Please select a campaign to load ad groups.");
      }
      return;
    }

    await getAdGroupList({
      variables: {
        advertiser_id: tiktokAccess.advertiser_id,
        token: tiktokAccess.access_token,
        campaignId,
      },
    });
  }, [brandBrief, getAdGroupList, getValues, errorMessage]);

  useEffect(() => {
    if (campaignData == null && brandBrief.brandProfile?.userProfile?.tiktokAccountAccess) {
      getCampaignsAndIdentities();
    } else if (!errorMessage) {
      setErrorMessage("Please link your TikTok account to load campaign data.");
    }
  }, [campaignData, getCampaignsAndIdentities, brandBrief, errorMessage]);

  useEffect(() => {
    getAdGroups();
  }, [watch('tiktokData.campaignId')]);

  useEffect(() => {
    const identityId = watch('tiktokData.adIdentityId');
    const displayname = campaignData?.identities?.find(
      (i) => i?.identity_id === identityId
    )?.display_name;

    setValue('tiktokData.displayName', displayname);
  }, [watch('tiktokData.adIdentityId'), campaignData, setValue]);

  return (
    <div ref={ref} className="flex flex-col gap-y-4">
      {errorMessage && (
        <NotificationCard
          type="warning"
          message={errorMessage}
          size="medium"
        />
      )}

      <FormControl>
        <InputLabel size="small" id="tiktok-campaign-id">
          Select TikTok campaign
        </InputLabel>
        <Controller
          name="tiktokData.campaignId"
          control={control}
          render={({ field }) => (
            <Select
              size="small"
              sx={{ maxWidth: '30%' }}
              label="Select TikTok campaign"
              id="tiktok-campaign-id"
              disabled={campaignsLoading || !campaignData}
              {...field}
              value={field.value ?? ""}
            >
              {TiktokCampaignOptions({ campaigns: campaignData?.campaigns })}
            </Select>
          )}
        />
      </FormControl>

      <FormControl>
        <InputLabel size="small" id="tiktok-adgroup-id">
          {adGroupData?.length ? 'Select TikTok ad group' : 'TikTok ad group missing'}
        </InputLabel>
        <Controller
          name="tiktokData.adgroupId"
          control={control}
          render={({ field }) => (
            <Select
              size="small"
              sx={{ maxWidth: '30%' }}
              label="Select TikTok ad group"
              id="tiktok-adgroup-id"
              disabled={!campaignData || adGroupsLoading || !adGroupData}
              {...field}
              value={field.value ?? ""} // Set to "" if null or undefined
            >
              {TiktokAdgroupOptions({ adGroups: adGroupData })}
            </Select>
          )}
        />
      </FormControl>

      <FormControl>
        <InputLabel size="small" id="tiktok-adIdentity-id">
          {TiktokIdentitiesOptions({ identities: campaignData?.identities })?.length
            ? 'Select TikTok Identity'
            : 'TikTok Identity Missing'}
        </InputLabel>
        <Controller
          name="tiktokData.adIdentityId"
          control={control}
          render={({ field }) => (
            <Select
              size="small"
              sx={{ maxWidth: '30%' }}
              label="Select TikTok Identity"
              id="tiktok-adIdentity-id"
              disabled={!campaignData || adGroupsLoading || !TiktokIdentitiesOptions({ identities: campaignData?.identities })}
              {...field}
              value={field.value ?? ""} // Default to empty string if value is null
            >
              {TiktokIdentitiesOptions({ identities: campaignData?.identities })}
            </Select>
          )}
        />
      </FormControl>

      <FormControl>
        <InputLabel size="small" id="tiktok-call-to-action">
          Select Call To Action
        </InputLabel>
        <Controller
          name="tiktokData.callToAction"
          control={control}
          render={({ field }) => (
            <Select
              size="small"
              sx={{ maxWidth: '30%' }}
              label="Select Call To Action"
              id="tiktok-call-to-action"
              disabled={!campaignData}
              {...field}
            >
              {CallToActionOptions()}
            </Select>
          )}
        />
      </FormControl>

      <StyledTextField
        size="small"
        sx={{ maxWidth: '30%' }}
        label="Destination URL"
        disabled={!campaignData}
        {...register('tiktokData.landingPageUrl')}
      />

      <Switch
        label="Tiktok spark ads"
        disabled={!campaignData}
        {...register('tiktokData.tikTokSparkAds')}
      />

      <StyledTextField
        size="small"
        sx={{ maxWidth: '30%' }}
        label="Ad caption"
        disabled={!campaignData}
        multiline
        {...register('tiktokData.adCaption')}
      />
    </div>
  );
});

export default TiktokTab;