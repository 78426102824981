import { Download } from '@mui/icons-material';
import { Button, CircularProgress, Dialog, DialogContent, DialogActions, Box, Typography  } from '@mui/material';
import { ENTITIES } from 'API';
import { Storage } from 'aws-amplify';
import { downloadFileFromUrl } from 'components';
import { addDays, format } from 'date-fns';
import {
  UseExportEntitiesToCSV,
  UseImportCreativeEarnings,
} from 'hooks/query/useAdminActions';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ACCEPT_CSV_FILES } from '../constants';
import FileUpload from './components/FileUpload';
import OopsModal from './components/OopsModal';
import { ErrorMessages, SuccessMessages } from './constants';
import { DateRangePicker, createStaticRanges, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const ImportTab: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [file, setFile] = React.useState<File | null>(null);
  const [modalMessage, setModalMessage] = React.useState<string | null>(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const customStaticRanges = createStaticRanges(
    defaultStaticRanges.filter(
      (range) =>
        range.label !== 'days up to today' && range.label !== 'days starting today'
    )
  );

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection',
    },
  ]);

  const { importEarnings, error } = UseImportCreativeEarnings();
  const { exportEntities, url, loading: exportRequestsLoading } = UseExportEntitiesToCSV();

  const togglePicker = () => setIsPickerOpen(!isPickerOpen);

  const onSelectFile = React.useCallback(async (files: File[]) => {
    const file = files?.[0];
    if (!file) return;

    if (file.type !== 'text/csv') {
      toast.error(ErrorMessages.InvalidFormat);
      return;
    }

    setFile(file);
    toast.success(SuccessMessages.FileSelected);
  }, []);

  const onError = useCallback(() => {
    if (!error?.message) return;

    setIsLoading(false);
    setModalMessage(error?.message?.replace('Error: ', ''));
  }, [error]);

  const onSuccess = () => {
    setIsLoading(false);
    toast.success(SuccessMessages.Imported);
  };

  useEffect(() => {
    onError();
  }, [error, onError]);

  const onSubmit = React.useCallback(async () => {
    if (!file) {
      toast.error(ErrorMessages.SelectFile);
      return;
    }
  
    if (!startDate || !endDate) {
      toast.error(ErrorMessages.InvalidDateRange);
      return;
    }
  
    // Adjust start and end dates to the start and end of the day in the local timezone
    const adjustedStartDate = new Date(startDate);
    adjustedStartDate.setHours(23, 59, 59, 999); // Start of day
  
    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setHours(23, 59, 59, 999); // End of day
  
    setIsLoading(true);
    const key = `imports/creative-payments-${new Date().toISOString()}.csv`;
    await Storage.put(key, file, {
      contentType: 'text/csv',
      level: 'public',
      acl: 'public-read',
    });
  
    console.log("adjustedEndDate.toISOString()", adjustedEndDate.toISOString());
    console.log("adjustedStartDate.toISOString()", adjustedStartDate.toISOString());
    console.log("key", key);
  
    await importEarnings({
      variables: {
        toDate: adjustedEndDate.toISOString(),
        fromDate: adjustedStartDate.toISOString(),
        key: `public/${key}`,
      },
    });
  
    onSuccess();
  }, [endDate, file, importEarnings, startDate]);

  const onCancel = () => {
    setModalMessage('');
  };

  const handleDateRangeChange = (item: any) => {
    const selectedRange = item.selection;
    setState([selectedRange]);
    setStartDate(selectedRange.startDate);
    setEndDate(selectedRange.endDate);
  };

  const onExportRequests = useCallback(() => {
    exportEntities({ variables: { entity: ENTITIES.ApprovedCreativeRequests } });
  }, [exportEntities]);

  useEffect(() => {
    if (url) downloadFileFromUrl(url);
  }, [url]);

  // Helper function to format the date range for display
  const formatDateRange = (startDate: Date | null, endDate: Date | null) => {
    if (!startDate || !endDate) return '';
    return `${format(startDate, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}`;
  };
  

  return (
    <div className="flex flex-col gap-9">
      <div className="flex justify-between w-full">
        <div className="max-w-[660px]">
          <div className="flex flex-col gap-9 w-full">
            {/* Button to open the DateRangePicker modal */}
            <Button
              onClick={togglePicker}
              variant="contained"
              color="primary"
              sx={{
                fontWeight: 700, // Semi-bold
                fontFamily: 'Inter, sans-serif',
              }}
            >
              {isPickerOpen ? 'Close Date Picker' : 'Open Date Picker'}
            </Button>

            {/* Display selected date range in a box */}
            {startDate && endDate && (
              <Box
                sx={{
                  border: '1px solid #e0e0e0',
                  borderRadius: '8px',
                  padding: '8px 16px',
                  backgroundColor: '#f9f9f9',
                  marginTop: '16px',
                  display: 'inline-block', // Makes it fit the content
                }}
              >
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 600, // Semi-bold
                  }}
                >
                  Selected Range: {formatDateRange(startDate, endDate)}
                </Typography>
              </Box>
            )}
            {/* DateRangePicker in a modal */}
            <Dialog
              open={isPickerOpen}
              onClose={togglePicker}
              maxWidth="md"
              fullWidth
              sx={{
                '& .MuiDialog-paper': {
                  width: '60vw', // 5% increase on each side (from 60vw to 70vw)
                  maxWidth: 'none', // Remove width constraint
                  height: 'auto', // Allow height to adjust automatically
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                },
              }}
            >
              <DialogContent>
                <DateRangePicker
                  onChange={handleDateRangeChange}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"
                  preventSnapRefocus={true}
                  calendarFocus="backwards"
                  staticRanges={customStaticRanges} // Apply custom static ranges here
                  inputRanges={[]} // This hides "days up to today" and "days starting today"
                  style={{ margin: '0 auto' }}
                  rangeColors={['#ff8730']}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={togglePicker} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <div>
              <FileUpload
                emptyMessage={file?.name}
                onFileSelect={onSelectFile}
                accept={ACCEPT_CSV_FILES}
              />
            </div>
          </div>
        </div>

        <div className="pl-10">
          <button
            className="bg-main-black text-white px-[22px] py-[4] w-[200px] h-[42px] rounded-[16px] text-[12px] disabled:bg-neutral-400 break-keep text-wrap"
            onClick={onExportRequests}
          >
            <div className="flex justify-evenly uppercase font-semibold items-center gap-x-1">
              {exportRequestsLoading ? (
                <CircularProgress className="text-white w-6 h-6" />
              ) : (
                <>
                  <Download />
                  <div>Creative Requests</div>
                </>
              )}
            </div>
          </button>
        </div>
      </div>

      <button
        onClick={onSubmit}
        disabled={isLoading}
        className="bg-main-black text-white px-[22px] py-[8px] rounded-[16px] text-[15px] break-keep text-wrap self-center font-semibold disabled:bg-gray-300"
      >
        {!isLoading ? 'Upload' : <CircularProgress className="w-4 h-4 text-white" />}
      </button>

      {modalMessage && <OopsModal message={modalMessage} onCancel={onCancel} />}
    </div>
  );
};

export default ImportTab;