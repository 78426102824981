import { zodResolver } from '@hookform/resolvers/zod';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import {
  ADMIN_STATUS,
  ApprovedAdType,
  CREATIVE_STATUS,
  LINK_AD_DATA,
  SEARCH_CREATIVE_REQUESTS_RESPONSE,
} from 'API';
import classNames from 'classnames';
import { UseGetCreativeRequest } from 'hooks';
import { UseLinkApprovedAds } from 'hooks/query/useApprovedAds';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { TextInput } from '../TextInput';
import ErrorModal from './components/ErrorModal/ErrorModal';
import {
  APPROVED_ADS_FORM_LENGTH,
  formSchema,
  FormSchema,
  LinkAdDialogTitle,
  linkApprovedAdsTypesConstant,
} from './constants';
import { filterDuplicate } from './helper';

interface ICreativeDetailsProps {
  creativeRequest?: SEARCH_CREATIVE_REQUESTS_RESPONSE | null;
  onSuccess?: () => Promise<void>;
}

interface ILinkApprovedAdsPayload {
  adId?: string;
  approvedAdType?: string;
}

interface ILinkApprovedFormPayload {
  approvedAdsData: ILinkApprovedAdsPayload[];
}

export const CreativeDetails = ({
  creativeRequest: requestData,
  onSuccess,
}: ICreativeDetailsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { linkAds, error } = UseLinkApprovedAds();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
  const { data: creativeRequest, getCreativeRequest } = UseGetCreativeRequest();

  const isApproved = useMemo(
    () =>
      creativeRequest?.adminApproval === ADMIN_STATUS.Approved &&
      creativeRequest?.status === CREATIVE_STATUS.Approved,
    [creativeRequest]
  );

  const approvedAds = useMemo(
    () => filterDuplicate(creativeRequest?.approvedAds?.items || []),
    [creativeRequest?.approvedAds?.items]
  );

  const {
    control,
    setValue,
    trigger,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      approvedAdsData: approvedAds.map((approvedAd) => ({
        approvedAdType: (approvedAd?.approvedAdType as ApprovedAdType) || '',
        adId: approvedAd?.ad_id || '',
      })),
    },
    mode: 'onChange',
  });

  const getRequest = async () => {
    if (requestData?.id) {
      setIsLoading(true);
      await getCreativeRequest({
        variables: { id: requestData.id },
        errorPolicy: 'ignore',
      });

      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    if (!creativeRequest && !isInitiallyLoaded) {
      getRequest();
      setIsInitiallyLoaded(true);
    }
  }, [requestData]);

  const disabledFields = useMemo(() => {
    const approvedAds = creativeRequest?.approvedAds?.items || [];

    // Convert to Map structure [index]: true or false
    return approvedAds?.reduce(
      (aggr, approvedAd, currentIndex) => ({
        ...aggr,
        [currentIndex]:
          !isApproved ||
          (approvedAd?.isManuallyLinked !== undefined &&
            approvedAd.isManuallyLinked === false),
      }),
      {}
    );
  }, [creativeRequest?.approvedAds?.items, isApproved]);

  const updateFormValues = useCallback(() => {
    if (!creativeRequest) {
      return;
    }

    setValue(
      'approvedAdsData',
      approvedAds.map((approvedAd) => {
        return {
          approvedAdType: (approvedAd?.approvedAdType as ApprovedAdType) || '',
          adId: approvedAd?.ad_id || '',
        };
      })
    );
  }, [creativeRequest, approvedAds]);

  useEffect(() => {
    updateFormValues();
  }, [creativeRequest]);

  const onSubmit = useCallback(
    async (formPayload: ILinkApprovedFormPayload) => {
      if (!creativeRequest?.id) {
        return;
      }

      setIsLoading(true);
      await linkAds({
        variables: {
          creativeId: creativeRequest?.id,
          approvedAdsData: formPayload?.approvedAdsData
            ?.filter(
              (approvedAd) => approvedAd.adId || approvedAd.approvedAdType
            )
            ?.map((approvedAd) => ({
              approvedAdType: approvedAd.approvedAdType,
              adId: approvedAd.adId,
              advertiserId: ' ',
            })) as LINK_AD_DATA[],
        },
      });

      if (onSuccess) {
        await onSuccess();
      }

      await getRequest();
      setIsLoading(false);
      toast.success('Linked successfully');
    },
    [creativeRequest]
  );

  useEffect(() => {
    if (!error) {
      return;
    }

    setIsErrorModalOpen(true);
    setIsLoading(false);
  }, [error?.message]);

  const onCloseErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  return isLoading || !creativeRequest ? (
    <div className="w-full h-full flex justify-center align-middle py-[50px]">
      <CircularProgress />
    </div>
  ) : (
      <div className="grid grid-cols-2 gap-4">
        <div className="max-h-[600px] overflow-y-auto w-full">
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            {Array.from({ length: APPROVED_ADS_FORM_LENGTH }).map((_, index) => (
                <div key={index} className="flex space-x-4 mb-4 w-full">
                  <Controller
                      control={control}
                      name={`approvedAdsData.${index}.adId` as const}
                      render={({ field }) => (
                          <div
                              className={classNames('text-black flex-grow', {
                                ['flex flex-col space-y-4']: index === 0,
                              })}
                          >
                            {index === 0 && <span className="font-semibold">AD ID</span>}
                            <TextInput
                                fullWidth
                                control={control}
                                placeholder="Enter Ad ID"
                                disabled={disabledFields[index + 1]}
                                label="AD ID"
                                sx={{ width: '100%' }} // Adjust width to full
                                {...field}
                            />
                          </div>
                      )}
                  />

                  <Controller
                      control={control}
                      name={`approvedAdsData.${index}.approvedAdType` as const}
                      render={({ field }) => (
                          <div
                              className={classNames('text-black flex-grow', {
                                ['flex flex-col space-y-4']: index === 0,
                              })}
                          >
                            {index === 0 && <span className="font-semibold">Channel</span>}
                            <FormControl fullWidth>
                              <InputLabel id={`approvedAdType-label-${index}`}>
                                Channel
                              </InputLabel>
                              <Select
                                  labelId={`approvedAdType-label-${index}`}
                                  id={`approvedADType-select-${index}`}
                                  label="Channel"
                                  sx={{ width: '100%' }} // Adjust width to full
                                  {...field}
                                  onChange={(event) => {
                                    field?.onChange?.(event);
                                    trigger(`approvedAdsData.${index}.adId`);
                                  }}
                                  value={field?.value || ''}
                              >
                                {linkApprovedAdsTypesConstant.map((linkApprovedAd) => (
                                    <MenuItem key={linkApprovedAd.id} value={linkApprovedAd.value}>
                                      {linkApprovedAd.label}
                                    </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                      )}
                  />
                </div>
            ))}

            <button
                type="submit"
                disabled={
                    !isValid ||
                    !isDirty ||
                    requestData?.status !== CREATIVE_STATUS.Approved
                }
                className="bg-main-black text-white font-bold py-2 px-4 min-w-[70px] min-h-[40px] rounded-[14px] items-end disabled:bg-neutral-400 text-center align-center"
            >
              <div className="flex flex-col justify-center items-center">
                {isLoading ? (
                    <CircularProgress className="w-4 h-4 text-white" />
                ) : (
                    'Save'
                )}
              </div>
            </button>
          </form>
        </div>

        <ErrorModal
            isOpen={isErrorModalOpen}
            message={error?.message || ''}
            onClose={onCloseErrorModal}
            title={LinkAdDialogTitle.FailedToLink}
        />
      </div>


  );
};
