import {
  ADMIN_STATUS,
  CreativeRequest,
  CREATIVE_REQUEST_LAST_UPDATED_BY,
  UserPaymentDetails,
} from 'API';
import AdminApprovalContent from 'components/adAdminApproval/Index';
import Modal from 'components/authentication/modal';
import Table, { Tdata } from 'components/table/Table';
import { useRequestStatusSendEmail } from 'hooks/query/useEmail';
import React, { Dispatch, FC, SetStateAction, useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { withAdmin } from 'state/admin';
import { AdminRoutes, AuthRoutes } from 'utils';
import { UpdateCreativeRequest } from '../../hooks';
import './adminDashboardTable.css';

type AdminDashboardTableProps = {
  paymentDetails: UserPaymentDetails[] | null;
  paymentLoading?: boolean;
  creativeRequestsData?: CreativeRequest[] | null;
  reqloading?: boolean;
  setIsShowBoxes: Dispatch<SetStateAction<boolean>>;
};

interface Transaction {
  paymentAmount: number; // The amount of the payment
  createdAt?: string; // Example date property (adjust if you have a date field)
}

const TRANSACTIONS_TABLE_HEADER_CELLS = [
  'transactions',
  'numberOfTransactions',
  'totalAmount',
];

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const AdminDashboardTable: FC<AdminDashboardTableProps> = ({
  paymentDetails,
  paymentLoading,
  creativeRequestsData,
  reqloading,
  setIsShowBoxes,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState<Tdata[]>([]);
  const [requestsTableData, setRequestsTableData] = useState<Tdata[] | null>(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showInspiration, setShowInspiration] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCreativeRequest, setSelectedCreatveRequest] = useState<CreativeRequest | null>(null);
  const { updateRequest, loading: updateCreativeRequestLoading } = UpdateCreativeRequest();
  const { sendEmailData } = useRequestStatusSendEmail();

  const getTotalAmount = (data: Transaction[]) =>
    Number(data.reduce((acc, item) => acc + item.paymentAmount, 0).toFixed(2));

  const GetDateMonthName = (date: string) => {
    const updatedDate = date?.split('-')[0];
    const dateMonth = updatedDate?.includes('.')
      ? updatedDate?.split('.')[1]
      : (updatedDate?.split('/')[1] as string | undefined);
    const dateYear = updatedDate?.includes('.')
      ? updatedDate?.split('.')[2]
      : updatedDate?.split('/')[2];

    let monthName = '';
    if (dateMonth) monthName = MONTHS[Number(dateMonth) - 1] || '';
    return `${monthName} ${dateYear}`;
  };

  // Memoize the grouped transactions map based on paymentDetails
  const groupedTransactions = useMemo(() => {
    const GroupMap = new Map();

    if (paymentDetails?.length) {
      for (const detail of paymentDetails) {
        const { userTransactions } = detail;
        if (userTransactions?.items.length) {
          for (const transaction of userTransactions.items) {
            const day = new Date(transaction?.createdAt || '');
            let date = '';
            day.setMonth(day.getMonth() + 1);
            date = `${new Date(transaction?.createdAt || '').toLocaleString().split(',')[0]}-${day.toLocaleString().split(',')[0]}`;  
            if (!GroupMap.has(date)) {
              GroupMap.set(date, [transaction]);
            } else {
              const existingTransactions = GroupMap.get(date);
              existingTransactions?.push(transaction);
            }
          }
        }
      }
    }

    // Sort the grouped transactions by date
    const sortedArray = Array.from(GroupMap).sort((a, b) => {
      return (
        new Date(b[0].split('-')[0].split('.').reverse().join('-')).getTime() -
        new Date(a[0].split('-')[0].split('.').reverse().join('-')).getTime()
      );
    });

    return new Map(sortedArray);
  }, [paymentDetails]);

  // Effect to set the table data when grouped transactions change
  useEffect(() => {
    if (groupedTransactions.size) {
      // Reset tableData to avoid duplicate entries when re-mounting the component
      const newTableData = [...groupedTransactions.entries()].map((transaction) => {
        return {
          transactions: GetDateMonthName(transaction[0]) || '',
          numberOfTransactions: transaction[1]?.length,
          totalAmount: `$${getTotalAmount(transaction[1])}`,
          transactionDate: transaction[0],
        };
      });
  
      setTableData(newTableData); // Set tableData to the newly created array
    }
  }, [groupedTransactions]); // Depend only on groupedTransactions
  
  // Effect to set requests table data
  useEffect(() => {
    setRequestsTableData(
      (creativeRequestsData?.slice(0, 10) || []).map((request) => ({
        id: request?.id || '',
        img: request.creatorProfile?.avatar || '/images/default-image.png',
        creativeId: request.uniqueId,
        creatorName: request?.creatorProfile?.name,
        activationName: request?.brief?.BriefName,
      }))
    );
  }, [creativeRequestsData]);

  const [hasDataLoaded, setHasDataLoaded] = useState(false); // Final loading state
  const [timeoutLoading, setTimeoutLoading] = useState(true); // Timeout-based loading

  // Set a 10-second timeout to end timeout-based loading
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeoutLoading(false);
    }, 10000); // 10 seconds in milliseconds

    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, []);

  // Watch for data loading status and timeout to update `hasDataLoaded`
  useEffect(() => {
    if (!paymentLoading && tableData.length > 0) {
      setHasDataLoaded(true); // Data has loaded, so set hasDataLoaded to true
    } else if (!timeoutLoading) {
      setHasDataLoaded(true); // Timeout is done, set hasDataLoaded to true
    }
  }, [paymentLoading, tableData, timeoutLoading]);


  useEffect(() => {
    const url = new URL(window.location.href);
    if (!url.searchParams.get('request')) {
      setIsShowBoxes(true);
      setShowInspiration(false);
      return;
    }

    setShowInspiration(true);
    setIsShowBoxes(false);
  }, [location]);

  const updateCreativeRequestStatus = async (
    newStatus: string,
    comment?: string,
    sendEmail?: boolean
  ) => {
    if (!selectedCreativeRequest) {
      return;
    }

    try {
      setLoading(true);

      const isApproved = newStatus === ADMIN_STATUS.Approved;
      const adminComment = [
        ...(selectedCreativeRequest.adminComment || []),
        comment || '',
      ];

      await updateRequest({
        variables: {
          input: {
            id: selectedCreativeRequest.id,
            adminApproval: ADMIN_STATUS[newStatus],
            lastUpdatedBy: CREATIVE_REQUEST_LAST_UPDATED_BY.ADMIN,
            ...(!isApproved && { adminComment }),
          },
        },
        errorPolicy: 'ignore',
      });

      if (sendEmail) {
        await sendEmailData({
          variables: {
            brandBriefId: selectedCreativeRequest.brandBriefId,
            creativeRequestUrl: `creatives?id=${selectedCreativeRequest.id}`,
            email: selectedCreativeRequest.creatorProfile?.email,
            name: selectedCreativeRequest?.creatorProfile?.name,
            brandBriefName: selectedCreativeRequest.BriefName,
            creativeUniqueId: selectedCreativeRequest.uniqueId,
            emailType: newStatus.toUpperCase(),
            feedback: comment || '',
          },
        });
      }

      setLoading(false);
      setShowSuccessModal(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="">
      {showInspiration ? (
        <div className="">
          <div onClick={(e) => e.stopPropagation()} className="z-[99]">
            <AdminApprovalContent
              videoUrl={selectedCreativeRequest?.tiktokCreativeUrl || ''}
              onClose={() => setShowInspiration(true)}
              request={selectedCreativeRequest}
              createAdPayload={{}}
              updateCreativeRequestStatus={updateCreativeRequestStatus}
              type="Admin"
              reqLoading={loading}
            />
          </div>
          <Modal
            isOpen={showSuccessModal}
            handleClose={() => {
              setIsShowBoxes(true);
              setShowInspiration(false);
              setShowSuccessModal(false);
            }}
            type="brand"
            content="The Status of the creative request was successfully changed"
          />
        </div>
      ) : (
        <div className="grid grid-cols-12 gap-[30px]">
          <>
            <div className="md:col-span-6 col-span-12">
              <h2
                className="text-2xl font-bold underline mb-4"
                style={{ fontFamily: 'Inter, sans-serif' }}
              >
                Payments
              </h2>
              <Table
                data={tableData as Tdata[]}
                rows={TRANSACTIONS_TABLE_HEADER_CELLS}
                loading={!hasDataLoaded} // Uses loading status with timeout
                pagination={1}
                extended={false}
                rowWidth="w-[110px]"
                textPosition="text-left"
                borderColor="#FF872F"
              />
            </div>

            <div className="md:col-span-6 col-span-12">
              <h2
                className="text-2xl font-bold underline mb-4"
                style={{ fontFamily: 'Inter, sans-serif' }}
              >
                Submissions
              </h2>
              <Table
                mainlyData={creativeRequestsData as Tdata[]}
                data={requestsTableData}
                rows={['creativeId', 'activationName', 'creatorName', 'details']}
                loading={reqloading || updateCreativeRequestLoading || !requestsTableData}
                pagination={1}
                extended={false}
                extendedURL={AdminRoutes.CreativeApproval}
                onRowClick={(request) => {
                  request && setSelectedCreatveRequest(request);
                  navigate(`${AuthRoutes.Dashboard}?request=true`);
                }}
                rowWidth="w-[110px]"
                borderColor="#FF872F"
                firstRowName="BRAND BRIEFS"
                textPosition="text-left"
              />
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default withAdmin(AdminDashboardTable);