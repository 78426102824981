import React, { useState,useCallback } from 'react';
import {isValidUrlBasic } from 'components';

const SocialInput = ({ value, onChange, placeholder, icon }) => {

    const [isValid, setIsValid] = useState(true);
    const invalidBorderClass = !isValid ? 'border-red-500' : '';

    const handleChange = useCallback((event) => {
        const newValue = event.target.value;
        onChange(newValue);
        setIsValid(isValidUrlBasic(newValue));
    }, [onChange]);

    return (
        <div className="flex justify-between items-center mb-[20px]">
            <div
                className={`rounded-[4px] py-[6px] max-w-[calc(100%-96px)] min-w-[122px] w-full flex justify-between items-center ${invalidBorderClass}`}>
                <input
                    type="text"
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                    className={`w-full text-[14px] font-[400] outline-none profile-input placeholder-gray-600 ${invalidBorderClass}`}
                />
                {value && <img src="/images/check-icon.svg" alt="Checked" />}
            </div>
            <div className="flex items-center justify-start w-[100px] pl-[19px]">
                <img className="h-[22px]" src={icon} alt="Social Media Icon" />
            </div>
            {!isValid && <span className="text-red-500 text-sm">Please enter a valid URL.</span>}
        </div>
    );
};

export default SocialInput;
